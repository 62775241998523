import { login, logout } from './auth'
import { getUserInfo, setUserToken, setUserInfo, resetUser } from './user'
import { toggleSiderBar, toggleSettingPanel } from './app'
import { addTag, emptyTaglist, deleteTag, closeOtherTags } from './tagsView'
export {
  login,
  logout,
  getUserInfo,
  setUserToken,
  setUserInfo,
  resetUser,
  toggleSiderBar,
  toggleSettingPanel,
  addTag,
  emptyTaglist,
  deleteTag,
  closeOtherTags
}
