import PERMISSION from '../utils/permission'

const menuList = [
  {
    title: 'Home',
    path: '/dashboard',
    icon: 'home',
    roles: [PERMISSION.IT, PERMISSION.ASSET, PERMISSION.SYSTEM, PERMISSION.STAFF]
  },
  // {
  //   title: '权限测试',
  //   path: '/permission',
  //   icon: 'lock',
  //   children: [
  //     {
  //       title: '系统管理员页面',
  //       path: '/permission/SystemPage',
  //       roles: [PERMISSION.SYSTEM]
  //     },
  //     {
  //       title: '资产管理员页面',
  //       path: '/permission/AssetPage',
  //       roles: [PERMISSION.ASSET]
  //     },
  //     {
  //       title: 'IT管理员页面',
  //       path: '/permission/ITPage',
  //       roles: [PERMISSION.IT]
  //     },
  //     {
  //       title: '员工页面',
  //       path: '/permission/StaffPage',
  //       roles: [PERMISSION.STAFF]
  //     }
  //   ]
  // },
  {
    title: 'Assets',
    path: '/asset',
    icon: 'transaction',
    children: [
      {
        title: 'Asset management',
        path: '/asset/management',
        roles: [PERMISSION.ASSET]
      },
      {
        title: 'Asset categories',
        path: '/asset/category',
        roles: [PERMISSION.IT]
      },
      {
        title: 'Custom attributes',
        path: '/asset/custom',
        roles: [PERMISSION.IT]
      },
      {
        title: 'Asset utilization',
        path: '/asset/require',
        roles: [PERMISSION.IT, PERMISSION.ASSET, PERMISSION.SYSTEM, PERMISSION.STAFF]
      },
      {
        title: 'Asset allocation',
        path: '/asset/allocation',
        roles: [PERMISSION.ASSET]
      },
      {
        title: 'Personal assets',
        path: '/asset/personal',
        roles: [PERMISSION.IT, PERMISSION.ASSET, PERMISSION.SYSTEM, PERMISSION.STAFF]
      }

    ]
  },
  {
    title: 'Individual',
    path: '/issue',
    icon: 'unordered-list',
    children: [
      {
        title: 'Pending items',
        path: '/issue/handle',
        roles: [PERMISSION.IT, PERMISSION.ASSET, PERMISSION.SYSTEM, PERMISSION.STAFF]
      },
      {
        title: 'My applications',
        path: '/issue/personal',
        roles: [PERMISSION.IT, PERMISSION.ASSET, PERMISSION.SYSTEM, PERMISSION.STAFF]
      }
    ]
  },
  {
    title: 'User management',
    path: '/user',
    icon: 'usergroup-add',
    roles: [PERMISSION.SYSTEM, PERMISSION.ASSET]
  },
  {
    title: 'Departments',
    path: '/department',
    icon: 'gold',
    roles: [PERMISSION.ASSET]
  },
  {
    title: 'Operating log',
    path: '/log',
    icon: 'database',
    roles: [PERMISSION.SYSTEM]
  }

]
export default menuList
