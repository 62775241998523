import React, { Component } from 'react'
import { Form, Input, Modal } from 'antd'
import { bool, PropTypes } from 'prop-types'

class ChangePasswordForm extends Component {
  validatePasswordSyntax = (rule, password, callback) => {
    if (password && !/^[a-zA-Z0-9]{4,20}$/.test(password)) {
      callback('Password must be a combination of 4-20 digits or letters.')
    }
    callback()
  }

  validatePasswordSame = (rule, value, callback) => {
    const { form } = this.props
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Passwords do not match.')
    }
    callback()
  }

  render () {
    const { visible, onCancel, onOk, form, confirmLoading } = this.props
    return (
      <Modal title="Change password" visible={visible} onCancel={onCancel}
        onOk={onOk} confirmLoading={confirmLoading}>
        <Form>
          <Form.Item label={'Current password'}>
            {form.getFieldDecorator('oldPassword', {
              rules: [{ required: true, message: 'Please enter your current password' }]
            })(<Input.Password placeholder='Current password'/>)}
          </Form.Item>
          <Form.Item label={'New password'}>
            {form.getFieldDecorator('newPassword', {
              rules: [{
                required: true,
                message: 'Please enter a new password'
              }, { validator: this.validatePasswordSyntax }]
            })(<Input.Password placeholder='New password'/>)}
          </Form.Item>
          <Form.Item label={'Repeat'}>
            {form.getFieldDecorator('confirmPassword', {
              rules: [{
                required: true,
                message: 'Please confir the new password'
              }, { validator: this.validatePasswordSame }]
            })(<Input.Password placeholder='Confirm new password'/>)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

ChangePasswordForm.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  form: PropTypes.object,
  confirmLoading: bool
}

export default Form.create()(ChangePasswordForm)
