const PERMISSION = {
  IT: 'IT',
  ASSET: 'ASSET',
  SYSTEM: 'SYSTEM',
  STAFF: 'STAFF'
}

export const CHINESE_PERMISSION = {
  IT: 'IT Administrator',
  ASSET: 'Asset Manager',
  SYSTEM: 'System Administrator',
  STAFF: 'Staff'
}

export const checkPermission = (roles, role) => {
  for (let i = 0; i < role.length; i++) {
    if (roles.includes(role[i])) {
      return true
    }
  }
  return false
}
export default PERMISSION
