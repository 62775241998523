import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import store from './store'
import Router from './router'
import defaultLocale from 'antd/es/locale/en_US'

class App extends Component {
  render () {
    return (
      <ConfigProvider locale={defaultLocale}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ConfigProvider>
    )
  }
}

export default App
