import React from 'react'
import './index.less'
const Logo = () => {
  return (
    <div className="sidebar-logo-container">
      <h1 className="sidebar-title"> Riko-Asset.digital</h1>
    </div>
  )
}

export default Logo
