export const USER_SET_USER_TOKEN = 'USER_SET_USER_TOKEN'
export const USER_SET_USER_INFO = 'USER_SET_USER_INFO'
export const USER_RESET_USER = 'USER_RESET_USER'

// app
export const APP_TOGGLE_SIDEBAR = 'APP_TOGGLE_SIDEBAR'
export const APP_TOGGLE_SETTINGPANEL = 'APP_TOGGLE_SETTINGPANEL'

// settings
export const SETTINGS_CHANGE_SETTINGS = 'SETTINGS_CHANGE_SETTINGS'

// tagsView
export const TAGSVIEW_ADD_TAG = 'TAGSVIEW_ADD_TAG'
export const TAGSVIEW_DELETE_TAG = 'TAGSVIEW_DELETE_TAG'
export const TAGSVIEW_EMPTY_TAGLIST = 'TAGSVIEW_EMPTY_TAGLIST'
export const TAGSVIEW_CLOSE_OTHER_TAGS = 'TAGSVIEW_CLOSE_OTHER_TAGS'

// monitor
export const BUG_ADD_BUG = 'BUG_ADD_BUG'
